import React, { Children, ReactNode, useEffect, useState } from 'react';
import styles from './response.module.scss';
import useThread from '@/pages/Thread/hooks/useThread';
import useBlock from '@/pages/Thread/hooks/useBlock';
import SourceIndicator from '../../SourceIndicator';
import CitationModal from '../CitationsModal';
import useTypingEffect from '@/pages/Thread/hooks/useTypingEffect';
import ReactMarkdown from 'react-markdown';
import { gaPushEvent } from '@/utils/analytics';

const Response = () => {
  const { status, id } = useThread();
  const { block, isLast } = useBlock();
  const { response, sources } = block;
  const [selectedChunk, setSelectedChunk] = useState<number | null>(null);
  const isTyping = isLast && status === 'TYPING';
  const { chunks, setChunks } = useTypingEffect(isTyping, response);

  useEffect(() => {
    if (status === 'READY' || status === 'CITING') {
      setChunks(response);
    }
  }, [response, setChunks, status]);

  useEffect(() => {
    if (isTyping && chunks.length === 0 && response.length > 0) {
      setChunks([
        {
          text: '',
          citation: response[0].citation,
        },
      ]);
    }
  }, [chunks.length, isTyping, response, setChunks]);

  const markdownWithCitations = chunks
    .map((chunk, chunkIndex) => {
      const { text, citation } = chunk;
      let res = text;

      if (citation?.length > 0) {
        res = JSON.stringify({
          text: text.replace(/"/g, '\\"'),
          artifacts: citation
            .map(({ source_id }) => ({
              chunk: chunkIndex,
              source: source_id,
            }))
            .filter(
              ({ chunk, source }) => chunk !== undefined && source !== undefined
            ),
        });
      }
      return res;
    })
    .join('');

  const parseMessageContent = (children: ReactNode) => {
    const parts = Children.toArray(children).flatMap((child) => {
      if (typeof child !== 'string') return child;

      const regex =
        /(.*?)\s*({(?:"text":[^{}]*(?:{[^{}]*})*[^{}]*,"artifacts":\[(?:{[^{}]*}(?:,\s*{[^{}]*})*)\])})/g;
      const matches = [...child.matchAll(regex)];

      if (matches.length === 0) return child;

      return matches.map((match) => {
        try {
          const { text, artifacts } = JSON.parse(match[2]);
          const details = artifacts as Array<{ chunk: number; source: number }>;

          const MarkdownSourceIndicator = (
            offset: number | undefined,
            children: ReactNode
          ) =>
            typeof offset !== 'undefined' &&
            offset === text.length &&
            !Array.isArray(children) &&
            details.map(({ chunk, source }, i) => (
              <SourceIndicator
                key={i}
                id={source}
                onClick={() => setSelectedChunk(chunk)}
              />
            ));

          return (
            <>
              <ReactMarkdown>{match[1]}</ReactMarkdown>
              <div>
                <ReactMarkdown
                  components={{
                    p: ({ children, node }) => (
                      <p>
                        {children}
                        {MarkdownSourceIndicator(
                          node?.position?.end?.offset,
                          children
                        )}
                      </p>
                    ),
                    li: ({ children, node }) => (
                      <li>
                        {children}
                        {MarkdownSourceIndicator(
                          node?.position?.end?.offset,
                          children
                        )}
                      </li>
                    ),
                  }}
                >
                  {text}
                </ReactMarkdown>
              </div>
            </>
          );
        } catch (e) {
          gaPushEvent('error_parsing_markdown');
          console.error({ e, thread: id, block: block.id, match });
          return child;
        }
      });
    });

    return parts;
  };

  const hasCitations = (text: string) => {
    return text.indexOf(',"artifacts":[') !== -1;
  };

  return (
    <div
      className={styles['response']}
      data-typing={isLast && status === 'TYPING'}
    >
      {chunks.length === 0 && <p className={styles['response-part']}></p>}
      <div className={styles['response-part']}>
        <ReactMarkdown
          components={{
            li: ({ children }) => {
              return <li>{parseMessageContent(children)}</li>;
            },
            p: ({ children }) => {
              if (hasCitations(children?.toString() || '')) {
                return <>{parseMessageContent(children)}</>;
              } else {
                return <p>{parseMessageContent(children)}</p>;
              }
            },
            code: ({ children }) => {
              if (children === '%cursor%') {
                return <i data-type="cursor"></i>;
              } else {
                return <code>{children}</code>;
              }
            },
          }}
          allowedElements={['code', 'pre', 'p', 'ul', 'ol', 'li']}
        >
          {`${markdownWithCitations}${status === 'TYPING' ? '`%cursor%`' : ''}`}
        </ReactMarkdown>
      </div>

      {selectedChunk !== null && (
        <CitationModal
          chunk={chunks[selectedChunk]}
          sources={sources}
          onClose={() => setSelectedChunk(null)}
        />
      )}
    </div>
  );
};

export default Response;
