import styles from './home-layout.module.scss';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { classnames } from '@/utils/functions';
import Topbar from '@/components/Topbar';
import GannetIcon from '@/assets/icons/gannet.svg?react';
import ExclamationIcon from '@/assets/icons/exclamation.svg?react';

interface HomeLayoutProps {
  showNav?: boolean;
  children: ReactNode;
}

const HomeLayout: FC<HomeLayoutProps> = ({ showNav = true, children }) => {
  const prompts: Array<string> = useMemo(
    () => [
      'How can GANNET help my humanitarian team?',
      'What are the current top 5 priority needs for IDPs in Sudan?',
    ],
    []
  );

  const [promptsStates, setPromptStates] = useState<Array<string>>([]);
  const [currentPrompt, setCurrentPrompt] = useState<string>('');
  const [promptIndex, setPromptIndex] = useState(0);

  useEffect(() => {
    setPromptStates(['']);
  }, []);

  useEffect(() => {
    if (promptIndex >= prompts.length) {
      return;
    }
    const interval = setInterval(() => {
      if (currentPrompt.length === prompts[promptIndex].length) {
        clearInterval(interval);
        setTimeout(() => {
          setPromptIndex((prev) => prev + 1);
          if (promptIndex < prompts.length - 1) {
            setPromptStates((prev) => [...prev, '']);
            setCurrentPrompt('');
          }
        }, 1500);
        return;
      } else {
        setCurrentPrompt(
          prompts[promptIndex].slice(0, currentPrompt.length + 1)
        );
      }
    }, 30);

    return () => clearInterval(interval);
  }, [currentPrompt.length, promptIndex, prompts]);

  return (
    <section className={styles['home-layout']}>
      <div className={styles['img-wrapper']}>
        {promptsStates.map((_, index) => (
          <div
            key={index}
            className={styles['prompt']}
            data-ready={index < promptIndex}
          >
            <span>
              <i>
                {index % 2 === 0 ? (
                  <GannetIcon className={styles['icon']} />
                ) : (
                  <ExclamationIcon className={styles['icon']} />
                )}
              </i>
              {index === promptIndex ? currentPrompt : prompts[index]}
            </span>
          </div>
        ))}
        <img src="/gannet-featured-image.png" alt="Gannet.ai Featured Image" />
      </div>

      <div
        className={classnames(styles['content'], {
          [styles['show-nav']]: showNav,
        })}
      >
        {showNav && <Topbar float={true} />}

        {children}
      </div>
    </section>
  );
};

export default HomeLayout;
