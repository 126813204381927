import useSession from '@/hooks/useSession';
import useBlock from '@/pages/Thread/hooks/useBlock';
import useThread from '@/pages/Thread/hooks/useThread';
import styles from './source-indicator.module.scss';
import { FC } from 'react';
import PDFIcon from '@/assets/icons/file-pdf.svg?react';
import NewsIcon from '@/assets/icons/newspaper.svg?react';

interface SourceIndicatorProps {
  id: number;
  onClick?: () => void;
}

const SourceIndicator: FC<SourceIndicatorProps> = ({
  id,
  onClick = undefined,
}) => {
  const { pushUserEvent } = useSession();
  const { status } = useThread();
  const { block, isLast } = useBlock();
  const sources = block.sources;
  const isPDF =
    typeof sources !== 'undefined' && sources?.length > id
      ? sources[id].url.includes('.pdf')
      : false;

  const className = styles['source-indicator'];
  return typeof onClick === 'function' ? (
    <button
      className={className}
      onMouseEnter={(elem) => {
        const parent = (elem.target as HTMLElement).closest('div');

        if (parent) {
          parent.style.color = 'var(--brand-light)';
        }
      }}
      onMouseLeave={(elem) => {
        (elem.target as HTMLElement).closest('div')!.style.color = '';
      }}
      onClick={() => {
        onClick();
        pushUserEvent('click_citation');
      }}
      disabled={isLast && status !== 'READY'}
    >
      {id}
    </button>
  ) : (
    <p className={styles['source-indicator-wrapper']}>
      <span className={className}>{id}</span>
      <i title={`Source is ${isPDF ? 'a PDF' : 'an article'}.`}>
        {isPDF ? <PDFIcon /> : <NewsIcon />}
      </i>
    </p>
  );
};

export default SourceIndicator;
